import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { SnsIcons } from 'components/Sns/SnsIcons';
import clsx from 'clsx';

import {
  selectCategories,
  selectFeatures,
  selectTopPageSections,
} from 'lib/util/privateMarketplace';
import { useCustomFooterLinks } from 'hooks/useCustomFooterLinks';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import { selectFooterSettings } from 'lib/util/footerSettingsShape';
import { useEssentialPageLinks } from 'hooks/useEssentialPageLinks';
import { appendQueryString } from 'lib/util/appendQueryString';

import baseStyles from '../base.module.css';
import styles from './PMPFooterView.module.css';
import { useQueryString } from 'hooks/useQueryString';

const PMPFooterView = () => {
  const { i18n, t } = useTranslation();

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const settings = useSelector(selectFooterSettings);
  const categories = useSelector(selectCategories);
  const features = useSelector(selectFeatures);
  const topPageSections = useSelector(selectTopPageSections);

  const customFooterLinks = useCustomFooterLinks();

  const showPopularProducts = topPageSections.includes('POPULAR_PRODUCTS');
  const showRecommendedProducts = topPageSections.includes('RECOMMENDED_PRODUCTS');
  const showCategory = topPageSections.includes('CATEGORY');
  const showFeature = topPageSections.includes('FEATURE');
  const essentialPageLinks = useEssentialPageLinks();

  const showEssentialPageLinks = bookingWidgetTheme === 'VIBRANT';

  const queryString = useQueryString();

  return (
    <footer
      className={clsx(
        styles['footer'],
        baseStyles['color-txt--white'],
        baseStyles['color-bg--black']
      )}
    >
      <div className={styles['footer__nav']}>
        {showEssentialPageLinks && essentialPageLinks.length > 0 && (
          <div className={styles['footer__nav__box']}>
            <ul className={styles['footer__nav__box__list']}>
              {essentialPageLinks.map((link, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  <Link href={link.url}>{link.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {(showPopularProducts || showRecommendedProducts) && (
          <div className={styles['footer__nav__box']}>
            <p className={styles['footer__nav__box__ttl']}>{t('Ranking')}</p>
            <ul className={styles['footer__nav__box__list']}>
              {showPopularProducts && (
                <li className={styles['footer__nav__box__list__item']}>
                  <Link href={appendQueryString('/top/popular', queryString)}>
                    {t('Popularity Ranking')}
                  </Link>
                </li>
              )}
              {showRecommendedProducts && (
                <li className={styles['footer__nav__box__list__item']}>
                  <Link href={appendQueryString('/top/recommended', queryString)}>
                    {t('Recommended Products')}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
        {showCategory && (
          <div className={styles['footer__nav__box']}>
            <p className={styles['footer__nav__box__ttl']}>{t('Find by Category')}</p>
            <ul className={styles['footer__nav__box__list']}>
              {categories.map((category, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  <Link
                    href={appendQueryString(
                      `/top/${encodeCategoryNameForURI(category.name)}`,
                      queryString
                    )}
                  >
                    {category.display_name || category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {showFeature && (
          <div className={styles['footer__nav__box']}>
            <p className={styles['footer__nav__box__ttl']}>{t('Featured')}</p>
            <ul className={styles['footer__nav__box__list']}>
              {features.map((feature, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  <Link
                    href={appendQueryString(
                      `/top/${encodeCategoryNameForURI(feature.name)}`,
                      queryString
                    )}
                  >
                    {feature.display_name || feature.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {customFooterLinks.length > 0 && (
          <div className={styles['footer__nav__box']}>
            <ul className={styles['footer__nav__box__list']}>
              {customFooterLinks.map((footerLink, idx) => (
                <li className={styles['footer__nav__box__list__item']} key={idx}>
                  {footerLink.custom_page_path ? (
                    <Link href={appendQueryString(footerLink.custom_page_path, queryString)}>
                      {footerLink.text}
                    </Link>
                  ) : (
                    <a href={footerLink.url} target="_blank" rel="noopener noreferrer">
                      {footerLink.text}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={clsx(styles['footer__inner'])}>
        <div className={styles['footer__info']}>
          <ul>
            <li>
              <img src="/static/images/family/ic_phone.svg" />
              {settings.phone}
            </li>
            <li>
              <img src="/static/images/family/ic_mail.svg" />
              {settings.email}
            </li>
          </ul>
          <ul>
            <li>
              <img src="/static/images/family/ic_location.svg" />
              {settings.address}
            </li>
            <li>
              <img src="/static/images/family/ic_time.svg" />
              {t('Hours: {{operatingHoursDescription}}', {
                operatingHoursDescription: settings.operatingHoursDescription,
              })}
            </li>
          </ul>
          <SnsIcons snsIds={settings?.snsIds} />
        </div>
        <div className={styles['footer__menu']}>
          <ul>
            {settings.showTravelAgencyRegistrationForm && (
              <>
                <li>
                  <a
                    href="https://ntmg.jp/registration.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Travel Agency Registration')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://ntmg.jp/agreement.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Agreement')}
                  </a>
                </li>
              </>
            )}
            {i18n.language === 'ja-JP' && settings.showJapaneseTerms && (
              <li>
                <a href="https://app.ntmg.com/ja/terms" target="_blank" rel="noopener noreferrer">
                  特定商取引法
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <p className={styles['footer__copy']}>© 2022 NutmegLabs Inc.</p>
    </footer>
  );
};

export default PMPFooterView;
